.product {
    padding: 20px 10px;
    background: $white;
    width: 100%;
    outline: none;
    position: relative;
    margin-bottom: 20px;

    @include mq(lg) {
        padding: 30px 15px;
    }

    &__image {
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
        padding-bottom: 90%;
        height: 0;

        @include mq(lg) {
            margin-bottom: 30px;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            height: 100%;
            width: auto;
        }
    }

    &__info {
        margin-bottom: 20px;

        @include mq(lg) {
            margin-bottom: 30px;
        }
    }

    &__title {
        color: $black;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 14px;

        @include mq(sm) {
            font-size: 20px;
        }
    }

    &__content {
        margin-bottom: 20px;
    }

    &__price {
        color: $purple;
        font-size: 28px;
        font-weight: 600;
        display: block;
        margin-bottom: 10px;
        line-height: 1;
    }

    &__size {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 10px;
        margin-left: -15px;
        margin-right: -15px;

        @include mq(sm) {
            height: 100px;
            margin-bottom: 15px;
        }

        .button {
            margin-bottom: 3px;
            margin-left: 3px;
            margin-right: 5px;
        }
    }

    &__description {
        color: $darkGray;
        text-decoration: none;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.category {
    background: $white;
    width: 100%;
    outline: none;
    position: relative;
    display: flex;
    padding: 20px;
    margin-bottom: 30px;
    align-items: center;
        
    @include mq(sm) {
        padding: 20px 30px;
    }

    &__image {
        position: relative;
        overflow: hidden;
        height: 0;
        width: 40%;
        height: 0;
        padding-bottom: 40%;

        @include mq(sm) {
            width: 50%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            height: 100%;
            width: auto;
        }
    }

    &__content {
        width: 60%;
        padding: 0 15px;

        @include mq(sm) {
            width: 50%;
            padding: 0 30px;
        }
    }

    &__supertitle {
        color: $black;
        text-transform: uppercase;
        font-size: 14px;

        @include mq(sm) {
            font-size: 16px;
        }
    }

    &__title {
        margin: 0;
        font-weight: 600;
        color: $purple;
        text-transform: uppercase;
        font-size: 18px;

        @include mq(sm) {
            font-size: 28px;
        }
    }

    &__description {
        margin-bottom: 20px;
    }
}
