.home {
    background: $lightestGray;
    overflow: hidden;

    &__hero {
        padding: 30px 0;

        &__slider {

            @include mq(md) {
                margin-bottom: 0 !important;
            }

            .slick-dots {
                bottom: 10px;

                @include mq(sm) {
                    bottom: 5%;
                }

                li {
                    button {
                        &:before {
                            font-size: 6px;

                            @include mq(sm) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        &__slide {
            position: relative;
            height: 0;
            padding-bottom: 80%;
            border-radius: 5px;
            background: $yellow;

            @include mq(sm) {
                padding-bottom: 66.4%;
            }
        }

        &__slide-background {
            position: absolute;
            z-index: 0;
            left: 0;
            top: 15px;
            bottom: 10px;
            overflow: hidden;
            width: 100%;

            img {
                position: absolute;
                top:0;
                bottom: 0;
                width: auto;
                height: 100%;
            }
        }

        &__slide-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            padding: 15px;

            @include mq(sm) {
                padding: 20px 40px 0;
            }
        }

        &__slide-info {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 20px;

            @include mq(sm) {
                margin-bottom: 30px;
            }
        }

        &__slide-info-text {
            margin: 0;
            font-weight: 900;
            font-size: 28px;
            color: $white;
            white-space: nowrap;

            @include mq(sm) {
                font-size: 64px;
            }

            span {
                display: block;
                color: $orange;
            }

            img {
                max-height: 28px;
                display: inline-block;
                margin: 0 10px;

                @include mq(sm) {
                    max-height: 70px;
                    margin: 0 20px;
                }
            }
        }

        &__slide-cta-text {
            color: $white;
            font-size: 18px;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 10px;

            @include mq(sm) {
                font-size: 21px;
                margin-bottom: 20px;
            }
        }

        &__slide-cta-button {
            outline: none;
            cursor: pointer;
            background: $orange;
            color: $white;
            font-size: 24px;
            border-radius: 5px;
            font-weight: 600;
            display: inline-block;
            padding: 10px 20px;
            text-decoration: none;

            &:hover {
                background: darken($orange, 5%);
                color: $white;
            }

            @include mq(sm) {
                font-size: 42px;
                border-radius: 10px;
                padding: 15px 30px;
            }
        }

        &__banner {
            position: relative;
            overflow: hidden;
            background: $white;
            height: 0;
            padding-bottom: 65%;
            margin-bottom: 30px;
            border-radius: 5px;
            display: block;
        }

        &__banner-stamp {
            position: absolute;
            top: 5%;
            left: 50%;
            transform: translateX(-50%);
            width: 75px;
            height: 75px;
            z-index: 3;
            background: url('/images/badge.svg') no-repeat center center / cover;
            color: $white;
            text-align: center;
            font-weight: 900;
            line-height: 74px;
            font-size: 12px;
        }

        &__banner-image {
            position: absolute;
            top: 0;
            left:0;
            right: 0;
            bottom:0;
            overflow: hidden;

            img {
                position: absolute;
                width: 100%;
                top:50%;
                transform: translateY(-50%);
            }
        }

        &__banner-content {
            width: 50%;
            padding: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            font-size: 18px;
            color: $white;
            padding-right: 0;

            @include mq(sm) {
                padding: 20px;
                font-size: 19px;
            }

            &--right {
                left: 50%;
                padding-left: 0;
                padding-right: 20px;
            }

            ._desc {
                font-weight: 900;
                line-height: 24px;
                margin: 0 0 12px;

                span {
                    color: $orange;
                    display: block;
                }
            }

            ._price {
                line-height: 1;
                margin: 0 0 15px;
                font-weight: 600;

                &--old {
                    text-decoration: line-through;
                    margin: 0 0 5px;
                }

                &--new {
                    font-size: 1.5em;
                }
            }
        }

        &__content-wrapper {
            margin-bottom: 20px;
        }
    }

    &__brands {
        margin-bottom: 40px;

        &__slider {
            background: $white;
            margin-bottom: 0 !important;
        }

        &__slide {
            padding: 15px 0 7px;

            @include mq(sm) {
                padding: 2vw 0;
            }
        }

        &__image {
            width: 100%;
            position: relative;
            height: 10vh;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                height: auto;
                width: 50%;
            }
        }
    }

    &__top-sales {
        margin-bottom: 40px;

        &__title {
            @include sectionTitle;
        }
    }

    &__politicas {
        background: $yellow;
        position: relative;
        padding: 40px 0;

        @include mq(sm) {
            padding: 60px 0;
        }


        &__title {
            color: $white;
            font-weight: 900;
            font-size: 32px;
            margin: 0;
            text-align: center;

            @include mq(sm) {
                text-align: right;
                font-size: 40px;
            }
        }

        &__wrapper {
             display: flex;
             flex-direction: column;
             justify-content: flex-start;
             align-items: center;
             padding: 20px;

             @include mq(sm) {
                 width: 80%;
                 padding: 40px 20px 40px 0;
             }
        }

        &__text {
            color: $white;
            font-weight: 600;
        }

        &__image {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__contact {
        padding: 40px 0;

        @include mq(sm) {
            padding: 60px 0;
        }

        &__title {
            color: $purple;
            font-size: 32px;
            text-align: center;
            font-weight: 900;
            margin-top: 0;

            @include mq(sm) {
                font-size: 40px;
            }
        }
    }
}
