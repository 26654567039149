.footer {
    background: $orange;
    color: $white;

    &__logo {
        padding: 20px 0;
    }

    &__title {
        color: $white;
        font-size: 24px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 10px;

        &--hidden {
            display: none;

            @include mq(sm) {
                display: block;
                color: transparent;
                opacity: 0;
            }
        }
    }

    &__list {
        margin-bottom: 20px;
    }

    &__item {
        margin-bottom: 5px;
        font-size: 14px;

        a {
            color: $white;
            text-decoration: none;
            font-weight: 300;
        }
    }

    &__social {
        display: flex;
        margin-bottom: 20px;

        @include mq(sm) {
            margin-bottom: 0;
        }

        li {
            &:not(:last-child) {
                margin-right: 40px;
            }
        }

        a {
            font-size: 32px;
        }
    }

    &__border {
        border-bottom: 1px solid $white;
    }

    &__copyright {
        margin: 20px 0;
        color: $white;
        font-size: 14px;
    }
}
