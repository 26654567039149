.cuenta-vendedores {
    background: $lightestGray;
    padding-bottom: 5%;

    &__section-title {
        color: $purple;
        font-size: 36px;

        @include mq(md) {
            font-size: 48px;
        }
    }

    &--datos-personales {
        &__hero {
            padding: 5% 0;
        }

        &__hero-title {
            margin: 0;
            text-align: center;
            color: $mainColor;
            background: $white;
            border: 1px solid $lightGray;
            padding: 3% 15px;
            border-radius: 10px;
        }

        &__form-section {
            padding: 5% 0;
            border-bottom: 1px solid $lightGray;
        }
    }

    &--ventas {
        &__section {
            display: none;

            &.-active {
                display: block;
            }
        }

        &__tabs {
            padding: 5% 0 0;
            
            .vb-form__group {
               
                @include mq(sm) {
                    margin-bottom: 0;
                    margin-top: -20px;
                    transform: translateY(-20px);
                }
            }
        }

        &__tab-selector {
            margin: 0;
            display: flex;
        }

        &__tab {
            padding: 15px;
            text-transform: uppercase;
            font-weight: 600;
            position: relative;
            cursor: pointer;
            color: $darkGray;
            font-size: 14px;

            @include mq(sm) {
                font-size: 16px;
            }

            &:before {
                @include trans;

                content: '';
                position: absolute;
                bottom: -4px;
                height: 3px;
                left: 0;
                right: 100%;
                display: block;
                background: $orange;
            }

            &.-active {
                color: $orange;
                font-weight: 900;

                &:before {
                    right: 0;
                }
            }
        }
    }

    &--preventa {
        &__form {
            padding-bottom: 5%;
        }

        &__title {
            text-align: center;
            color: $purple;
        }

        &__subtitle {
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            color: $darkGray;

            @include mq(md) {
                font-size: 21px;
            }
        }
    }

    &__content-block {
        margin: 0 0 20px;
        text-align: center;
        color: $mainColor;
        background: $white;
        border: 1px solid $lightGray;
        padding: 3% 15px;
        border-radius: 10px;

        p {
            font-size: 18px;
            margin-bottom: 15px;
        }

        &--ghost {
            border: none;
            background: transparent;
        }
    }
}