.landing{
    background: $lightestGray;

    section {
        padding: 40px 0;

        @include mq(sm) {
            padding: 7% 0;
        }
    }

    &__hero {
        position: relative;

        &__ornaments {
            position: absolute;
            top: 0;
            right:0;
            bottom:0;
            left: 0;
            overflow: hidden;
            display: none;

            @include mq(sm) {
                display: block;
            }

            img {
                position: absolute;
            }

            ._left-big {
                top: 20%;
                left:0;
            }

            ._left-small {
                top: 15%;
                left:10%;
            }

            ._right {
                right: 5%;
                transform: rotate(150deg);
                width: 15vw;
            }
        }

        &__content {
            position: relative;
            text-align: center;
            width: 100%;
            max-width: 900px;
            margin-left: auto;
            margin-right: auto;
            color: $darkGray;
            padding: 3% 0 7%;
        }

        &__title,
        &__subtitle {
            color: $orange;
            line-height: 1;
            margin: 0 0 10px;
        }

        &__illustrations {
            position: absolute;
            top: 0;
            right:0;
            bottom:0;
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            overflow: hidden;

            img {
                width: 50%;
                opacity: .4;

                @include mq(sm) {
                    width: auto;
                    opacity: 1;
                }
            }
        }

        &__title {
            font-size: 36px;

            @include mq(md) {
                font-size: 60px;
            }
        }

        &__subtitle {
            font-size: 24px;

            @include mq(md) {
                font-size: 48px;
            }
        }

        &__text {
            font-weight: 600;
            max-width: 600px;
            margin: 60px auto 100px;
        }
    }

    &__video {
        background: $yellow url('/images/bones-pattern.png') repeat center center;
    
        &__wrapper {
            width: 100%;
            position: relative;
            height: 0;
            padding-bottom: 56.5%;
            overflow: hidden;

            iframe {
                position: absolute;
                top: 0;
                right:0;
                bottom:0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__signup {
        background: $white;
        position: relative;

        img {
            max-width: 100%;
        }

        &__ornaments {
            position: absolute;
            top: 0;
            right:0;
            bottom:0;
            left: 0;
            overflow: hidden;
            display: none;

            @include mq(sm) {
                display: block;
            }

            img {
                position: absolute;
            }

            ._left-big {
                top: 50%;
                left:5%;
                transform: translateY(-50%);
            }

            ._left-small {
                top: 15%;
                left:10%;
            }

            ._right-big {
                top: 50%;
                right:5%;
                transform: translateY(-80%);
            }

            ._right-small {
                top: 50%;
                right:10%;
            }
        }

        &__title {
            color: $orange;
            font-size: 24px;
            text-align: center;
            font-weight: 900;
            margin: 0 0 40px;

            @include mq(md) {
                font-size: 36px;
            }
        }

        &__subtitle {
            font-size: 21px;
            font-weight: 900;
            color: $orange;
            margin: 0;

            @include mq(md) {
                font-size: 24px;
            }
        }

        &__section {
            padding-bottom: 40px;
        }

        #vet,
        #student {
            @include trans;

            max-height: 0;
            overflow: hidden;
            opacity: 0;
            pointer-events: none;

            &.-active {
                max-height: 50vh;
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    &__contacto {
        background: $purple url('/images/iso-perro-purple-big.svg') no-repeat center right / 60vh;

        &__title {
            color: $white;
            font-size: 32px;
            text-align: center;
            font-weight: 900;
            margin-top: 0;

            @include mq(sm) {
                font-size: 40px;
            }
        }

        .vb-form__label {
            color: $white;
        }
    }
}