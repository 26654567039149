.header {
    $h: &;

    position: fixed;
    z-index: 1000;
    top:0;
    left:0;
    right:0;
    border-bottom: 3px solid $lightGray;
    background: $white;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--top {
            padding: 10px 0;

            @include mq(sm) {
                padding: 30px 0 20px;
            }
        }
    }

    &__logo {
        height: 40px;

        @include mq(sm) {
            height: 50px;
            margin-right: 15px;
        }

        img {
            height: 100%;
            width: auto;
        }
    }

    &__search {
        @include trans;

        opacity: 0;
        pointer-events: none;
        position: fixed;
        top: 60px;
        background: $white;
        left: 0;
        width: 100%;
        transform: translateX(-100%);

        @include mq(sm) {
            width: 45%;
            display: block;
            position: static;
            opacity: 1;
            pointer-events: all;
            transform: translateX(0);
        }

        &.-active {
            opacity: 1;
            pointer-events: all;
            transform: translateX(0);
            z-index: 3;

            & + .header__actions {
                .header__search-toggle {
                    i.fa-search {
                        display: none;
                    }
                    i.fa-times {
                        display: block;
                    }
                }
            }
        }

        &__form {
            width: 100%;
            position: relative;
        }

        &__input {
            border: none;
            height: 32px;
            border-top: 2px solid $lightGray;
            border-bottom: 2px solid $lightGray;
            width: 100%;
            padding: 0 42px 0 10px;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            @include mq(sm) {
                border-radius: 5px;
                border: 2px solid $lightGray;
                height: 50px;
                padding: 0 65px 0 15px;
            }
        }

        &__button {
            background: transparent;
            border: none;
            cursor: pointer;
            height: 32px;
            width: 32px;
            z-index: 1;
            position: absolute;
            top: 0;
            right:0;
            display: flex;
            justify-content: center;
            align-items: center;

            &:focus {
                box-shadow: none;
                outline: none;
            }

            i {
                color: $purple;
            }

            @include mq(sm) {
                height: 50px;
                width: 50px;
            }
        }
    }

    &__search-toggle {
        width: 40px;
        height: 40px;
        font-size: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $orange;

        @include mq(sm) {
            display: none;
        }

        i.fa-search {
            display: block;
        }
        i.fa-times {
            display: none;
        }
    }


    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 40px;
        flex-grow: 2;
        padding-left: 10px;

        @include mq(sm) {
            flex-grow: 0;
            justify-content: space-between;
        }

        &__icon {
            width: 28px;
            height: 28px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;

            @include mq(sm) {
                margin-right: 10px;
            }

            @include mq(lg) {
                margin-right: 10px;
                width: 36px;
                height: 36px;
            }

            img {
                height: 100%;
                width: auto;
            }

            span {
                position: absolute;
                top: -2px;
                right: -4px;
                background: $orange;
                font-size: 10px;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                color: $white;
                text-align: center;
                line-height: 16px;
            }
        }

        &__text {
            color: $darkGray;
            white-space: nowrap;
            font-size: 14px;
            display: none;

            @include mq(sm) {
                display: block;
            }

            @include mq(lg) {
                font-size: 16px;
            }
        }
    }

    &__login {
        position: relative;
        height: 100%;
        padding: 0 10px;

        &__button {
            display: flex;
            align-items: center;
            text-decoration: none;
            height: 100%;
            padding-right: 16px;
            cursor: pointer;
        }

        &__button-chevron {
            @include trans;

            width: 8px;
            height: 8px;
            border-bottom: 1px solid $black;
            border-right: 1px solid $black;
            cursor: pointer;
            transform: rotate(45deg);
            position: absolute;
            right:10px;
            top: calc(50% - 6px);
        }

        &__dropdown {
            @include trans;

            position: absolute;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            border: 2px solid $lightGray;
            width: 105%;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            padding: 0 15px;
            background: $white;
            min-width: 150px;

            @include mq(sm) {
                top: 110%;
            }

            &:after {
                content: '';
                width: 10px;
                height: 10px;
                border-top: 2px solid $lightGray;
                border-right: 2px solid $lightGray;
                transform: rotate(-45deg);
                position: absolute;
                top: -6px;
                right: calc(50% - 22px);
                background: $white;

                @include mq(sm) {
                    right: 10px;
                }
            }
        }

        &__dropdown-item {

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &__link {
                display: flex;
                align-items: center;
                text-decoration: none;
                color: $black;
            }

            &__icon {
                margin-right: 10px;
            }

            &__name {
                font-size: 14px;
                white-space: nowrap;
            }
        }


        &.-active {
            .header__login__dropdown {
                opacity: 1;
                max-height: 300px;
                z-index: 3;
                overflow: visible;
                padding: 15px 15px;
            }

            .header__login__button-chevron {
                transform: rotate(-135deg);
                top: calc(50% - 2px);
            }
        }
    }

    &__shop-cart {
        margin-left: 5px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        text-decoration: none;
        height: 100%;

        @include mq(md) {
            margin-left: 30px;
        }
    }

    &__nav {
        @include trans;

        position: fixed;
        width: 100%;
        top: 60px;
        left:0;
        right:0;
        bottom: 0;
        transform: translateX(-100%);
        overflow: auto;
        background: transparentize($white, .1);
        border-top: 2px solid $lightGray;
        padding-top: 40px;

        @include mq(md) {
            position: static;
            display: flex;
            justify-content: space-between;
            background: none;
            transform: translateX(0%);
            padding-top: 0;
            border-top: none;
            overflow: visible;
        }

        &__list {

            @include mq(md) {
                display: flex;
            }
        }

        &__item {
            position: relative;
            // border-bottom: 1px solid $lightGray;

            @include mq(md) {
                height: 100%;
                display: flex;
            }

            &:hover {

                .header__nav__link {
                    color: $orange;

                    &:after {
                        content: '';
                        display:none;
                        height: 3px;
                        border: none;
                        width: 100%;
                        background: $orange;
                        position: absolute;
                        top: calc(100%);
                        left: 0;

                        @include mq(sm) {
                            display:block;
                        }
                    }

                    & + .header__nav__sublist {
                        max-height: 120vh;
                        opacity: 1;
                        pointer-events: all;
                    }
                }
            }
        }

        &__link {
            @include trans;

            font-weight: 600;
            text-transform: uppercase;
            font-size: 20px;
            color: $darkGray;
            text-decoration: none;
            letter-spacing: 1.5px;
            cursor: pointer;
            background: transparent;
            border: none;
            outline: none;
            letter-spacing: 1px;
            display: block;
            padding: 10px 15px;

            @include mq(md) {
                font-weight: 900;
                font-size: 14px;
                padding: 10px 15px 20px;
            }

            &:after {
                @include trans;

                content: '';
            }

            &:hover {
                color: $orange;
            }

            &--vendedores {
                color: $orange;
            }


        }

        &__sublist {
            @include trans;

            opacity: 0;
            pointer-events: none;
            z-index: 5;
            overflow: hidden;
            max-height: 0;

            @include mq(md) {
                background: $white;
                display:block;
                border-left: 2px solid $lightGray;
                border-right: 2px solid $lightGray;
                border-bottom: 2px solid $lightGray;
                padding: 20px 15px;
                position: absolute;
                top: calc(100% + 3px);
                left: 0;
                max-height: none;
            }
        }

        &__subitem {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid $lightGray;

            @include mq(md) {
                border-bottom: none;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }

            a {
                text-transform: none;
                font-size: 16px;
                letter-spacing: 0.5px;
                white-space: nowrap;
                font-weight: 600;
                color: $darkestGray;
                text-decoration: none;
                padding: 10px 30px;

                &:hover {
                    color: $orange;
                }

                @include mq(md) {
                    font-size: 14px;
                    padding: 0;
                }
            }
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;

        span {
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }

        @include mq(md) {
            display: none;
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
        }
    }

    & + main {
        padding-top: 70px;

        @include mq(sm) {
            padding-top: 100px;
        }

        @include mq(md) {
            padding-top: 150px;
        }
    }

    &--landing {
        .button {
            margin-left: 10px;

            @include mq(sm) {
                margin-left: 15px;
            }

            &__link {
                @include mq(xs) {
                    padding: 0 10px;
                    font-size: 14px;
                    min-width: 12px;
                }
            }
        }

        #{$h}__wrapper {
            padding: 10px 0;

            @include mq(sm) {
                padding: 30px 0;
            }
        }

        #{$h}__actions {
            @include mq(xs) {
                padding:0;
            }
        }

        #{$h}__logo {
            height: 25px;

            @include mq(sm) {
                height: 50px;
            }
        }

        & + main {
            padding-top: 70px;

            @include mq(sm) {
                padding-top: 100px;
            }

            @include mq(md) {
                padding-top: 120px;
            }
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
