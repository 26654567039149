.button {
    &__label {
        display: block;
        background: $purple;
        color: $white;
        border-radius: 5px;
        opacity: 0.3;
        cursor: pointer;
        line-height: 40px;
        width: 80px;
        height: 40px;
        text-align: center;
    }

    &__input {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked + label {
        opacity: 1;
    }

    &__link,
    &__submit {
        @include trans;

        display: inline-block;
        color: $white;
        background: $orange;
        padding: 0 25px;
        border-radius: 5px;
        text-decoration: none;
        cursor: pointer;
        white-space: nowrap;
        font-size: 16px;
        font-weight: 600;
        height: 45px;
        line-height: 41px;
        text-align: center;
        min-width: 120px;
        border:none;
        border: 2px solid transparent;

        &:hover {
            background: darken($orange, 10%);
            color: $white;
        }

        @include mq(sm) {
            min-width: 170px;
        }

        @include mq(lg) {
            height: 60px;
            line-height: 56px;
        }

        &.button--ghost {
            border: 2px solid $orange;
            background: transparent;
            color: $orange;

            &:hover {
                background: rgba(0,0,0,.05);
            }
        }
    }

    &.button--icon {
        min-width: none;
        width: 32px;
        height: 32px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        border: none;
        -webkit-appearance: none;
        appearance: none;
        text-align: center;
        cursor: pointer;
        
        i {
            @include trans;
            color: $darkGray;
        }

        &:hover {
            i {
                color: $orange;
            }
        }
    }

    &__select {
        width: 100%;
        padding: 10px;
        border: 2px solid $lightGray;
        border-radius: 5px;
        outline: none;
        color: $darkGray;
        background: $white;
        height: 50px;
    }
}
